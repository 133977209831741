import React, { useEffect, useRef, useState } from 'react';
import {
	Form,
	Input,
	Select,
	Modal,
	Steps,
	Button,
	message,
	InputNumber,
	Spin,
} from 'antd';
import OTPForm from '../../../components/global/OTPForm';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import {
	fetchBulkPaymentsUploads,
	savePayment,
} from '../../../features/payment/paymentSlice';
import { sendTransOtp } from '../../../features/global/globalSlice';
import { verifyTransOtp } from '../../../features/auth/authSlice';
import {
	fetchBranchWallets,
	fetchWallets,
} from '../../../features/wallet/walletSlice';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

const { Option } = Select;

const PayFundModalOne = ({ open, closeModalOne, prefetchData }) => {
	const dispatch = useDispatch();
	const { disbursementWallets, walletLoading, branchWallets } = useSelector(
		(state) => state.wallet
	);
	const { banks, payLoading } = useSelector((state) => state.payment);
	const { user } = useSelector((state) => state.auth);
	const { branches, branchLoading } = useSelector((state) => state.branch);

	const [form] = Form.useForm();
	const formRef = useRef(null);
	const contentStyle = {};

	const [current, setCurrent] = useState(0);
	const [data, setdata] = useState({});
	const [otpVerified, setotpVerified] = useState('');
	const [response, setresponse] = useState('');
	const [isSuccess, setisSuccess] = useState(false);
	const [activeBranch, setactiveBranch] = useState();

	let allWallets = [...disbursementWallets, ...branchWallets];

	let srcName = allWallets?.find(
		(item) => Number(item?.walCode) === Number(data?.bkluWalCode)
	);

	const usrObj = {
		usrId: user?.usrId,
		blkuMethod: 'DIRECT',
		brnId: activeBranch?.brnId ?? user?.brnId,
		orgId: user?.orgId,
	};

	function handleOtpVerified(val) {
		setotpVerified(val);
	}

	const next = async () => {
		const otpObj = {
			usrId: user?.usrId,
		};
		if (current === 0) {
			if (
				data?.bkluWalCode === undefined ||
				data?.blkBeneficiaryBank === undefined ||
				data?.blkBeneficiaryAccount === undefined ||
				data?.blkBeneficiaryDetails === undefined ||
				data?.blkPaymentAmount === undefined ||
				data?.blkPaymentReason === undefined ||
				data?.blkuType === undefined
			) {
				return message.info('Please fill in the required fields');
			} else {
				setCurrent(current + 1);
			}
		} else if (current === 1) {
			const res = await dispatch(sendTransOtp(otpObj));
			if (res?.payload?.success) {
				setCurrent(current + 1);
			} else {
				message.info('We cannot complete your request at the moment');
			}
		}
	};

	const prev = () => {
		setCurrent(current - 1);
	};

	function handleDone() {
		setCurrent(0);
		form.resetFields();
		setdata({});
		closeModalOne();
	}

	function handleReset() {
		setCurrent(0);
	}

	function handleSelectChange2(value) {
		setdata((prevData) => ({
			...prevData,
			blkBeneficiaryBank: value,
		}));
	}

	function handleSelect(value, fileName) {
		setdata((prevData) => ({
			...prevData,
			[fileName]: value,
		}));
	}

	function handleAmountChange(value) {
		setdata((prevData) => ({
			...prevData,
			blkPaymentAmount: value,
		}));
	}

	const handleChange = (e) => {
		setdata((prevData) => ({
			...prevData,
			[e.target.name]: e.target.value,
		}));
	};

	async function handleBranchChange(brnId) {
		await setdata({ ...data, bkluWalCode: null });
		let actvBrn = await branches?.find((item) => item?.brnId === brnId);
		await setactiveBranch(actvBrn);
		await dispatch(fetchBranchWallets({ ...usrObj, brnId: actvBrn?.brnId }));
	}

	const onFinish = async () => {
		data.blkuCreatedBy = user?.usrId;
		data.blkuMethod = 'DIRECT';
		data.blkuPaymentMode = 'EFT_RTGS';
		data.blkuTotalamount = Number(data.blkPaymentAmount);
		data.blkuFileDescription = data.blkPaymentReason;
		data.blkPaymentDesc = data.blkPaymentReason;
		data.blkReceiverMobileNo = data.blkBeneficiaryAccount;

		const otpObj = {
			usrId: user?.usrId,
			usrSalt: otpVerified,
		};

		if (otpVerified?.length === 4) {
			const resp = await dispatch(verifyTransOtp(otpObj));
			if (resp?.payload?.success) {
				const res = await dispatch(savePayment(data));
				if (res?.payload?.success) {
					setisSuccess(true);
					setresponse(res?.payload?.messages?.message);
					const resp = await dispatch(fetchBulkPaymentsUploads(usrObj));
					if (resp?.payload?.success) {
						prefetchData();
					}
				} else {
					setisSuccess(false);
					setresponse(res?.payload?.messages?.message);
				}
				await setCurrent(3);
			} else {
				return message.error('Could not verify code. Please try again');
			}
		} else {
			return message.info('Enter the four digit verification code');
		}
	};

	async function handleFetchWallets() {
		await dispatch(fetchWallets(usrObj));
	}

	async function handleCancel() {
		await form.resetFields();
		await setactiveBranch();
		await setdata({});
		await closeModalOne();
	}

	useEffect(() => {}, [
		data,
		banks,
		branches,
		branchWallets,
		activeBranch,
		disbursementWallets,
	]);

	useEffect(() => {
		handleFetchWallets();
	}, []);

	const steps = [
		{
			title: 'Payment Info',
			key: 'Payment Info',
			content: (
				<>
					<div className='mb-4'>
						<label className='mb-2 flex items-center' for='blkuType'>
							<span className='text-[#ff4d4f] text-[21px] mr-1 mt-[5px]'>
								*
							</span>
							Payment Mode
						</label>
						<Select
							value={data?.blkuType}
							name={'blkuType'}
							defaultValue='Choose'
							style={{
								width: '100%',
							}}
							onChange={(e) => handleSelect(e, 'blkuType')}>
							<Option value={'PESALINK'}>PesaLink</Option>
							<Option value={'SWIFT'}>Swift</Option>
							<Option value={'EFT '}>EFT </Option>
							<Option value={'AAT'}>AAT</Option>
						</Select>
					</div>

					{Number(user?.usrRoleId) === 0 ? (
						<div className='my-4'>
							<label className='mb-1 flex items-center' for='waltTo'>
								<span className='text-[#ff4d4f] text-[21px] mr-1 mt-[5px]'>
									*
								</span>{' '}
								Payment Source
							</label>
							<div className='flex items-center'>
								{branches?.length > 1 ? (
									<Select
										value={activeBranch?.brnId}
										loading={branchLoading}
										onChange={(e) => handleBranchChange(e)}
										defaultValue='Select Branch'
										style={{
											width: '190px',
											marginRight: 20,
										}}>
										{branches?.map((item) => {
											return (
												<>
													<Option value={item?.brnId}>{item?.brnName}</Option>
												</>
											);
										})}
									</Select>
								) : null}

								<Select
									value={data?.bkluWalCode}
									loading={walletLoading}
									defaultValue='Choose'
									style={{
										width: '100%',
									}}
									onChange={(e) => handleSelect(e, 'bkluWalCode')}>
									{branchWallets
										?.filter((item) => item.walType === 'DISBURSEMENT')
										?.map((item) => {
											return (
												<>
													<Option
														value={
															item?.walCode
														}>{`${item?.walDesc} - ${item?.walType} (KES ${item?.walAmount})`}</Option>
												</>
											);
										})}
								</Select>
							</div>
						</div>
					) : (
						<div className='my-4'>
							<label className='mb-1 flex items-center' for='waltTo'>
								<span className='text-[#ff4d4f] text-[21px] mr-1 mt-[5px]'>
									*
								</span>{' '}
								Payment Source
							</label>
							<Select
								value={data?.bkluWalCode}
								loading={walletLoading}
								defaultValue='Choose'
								style={{
									width: '100%',
								}}
								onChange={(e) => handleSelect(e, 'bkluWalCode')}>
								{disbursementWallets?.map((item) => {
									return (
										<>
											<Option
												value={
													item?.walCode
												}>{`${item?.walDesc} - ${item?.walType} (KES ${item?.walAmount})`}</Option>
										</>
									);
								})}
							</Select>
						</div>
					)}

					<Form.Item
						rules={[
							{
								required: true,
								message: 'Enter amount',
							},
						]}
						name={'blkPaymentAmount'}
						label='Amount'>
						<InputNumber
							onChange={handleAmountChange}
							name={'blkPaymentAmount'}
							type='number'
							min={10}
							max={499999}
							className='rounded-[8px] h-[44px] w-full'
						/>
					</Form.Item>

					<div className='mb-4'>
						<label className='mb-2 flex items-center' for='blkBeneficiaryBank'>
							<span className='text-[#ff4d4f] text-[21px] mr-1 mt-[5px]'>
								*
							</span>
							Bank
						</label>
						<Select
							name={'blkBeneficiaryBank'}
							defaultValue='Choose'
							style={{
								width: '100%',
							}}
							onChange={(e) => handleSelectChange2(e)}>
							{banks?.map((item) => {
								return (
									<>
										<Option value={item?.bnkCode}>{item?.bnkName}</Option>
									</>
								);
							})}
						</Select>
					</div>

					<Form.Item name={'blkBeneficiaryBranch'} label='Bank Branch'>
						<Input
							name={'blkBeneficiaryBranch'}
							onChange={handleChange}
							className='rounded-[8px] h-[44px]'
						/>
					</Form.Item>

					<Form.Item
						rules={[
							{
								required: true,
								message: 'Enter receiver account number',
							},
						]}
						name={'blkBeneficiaryAccount'}
						label='Account No.'>
						<Input
							name={'blkBeneficiaryAccount'}
							onChange={handleChange}
							type='number'
							className='rounded-[8px] h-[44px]'
						/>
					</Form.Item>

					<Form.Item
						rules={[
							{
								required: true,
								message: 'Enter receiver name',
							},
						]}
						name={'blkBeneficiaryDetails'}
						label='Account Name'>
						<Input
							name={'blkBeneficiaryDetails'}
							onChange={handleChange}
							className='rounded-[8px] h-[44px]'
						/>
					</Form.Item>

					<Form.Item
						rules={[
							{
								required: true,
								message: 'Enter your reason for transfer',
							},
						]}
						name={'blkPaymentReason'}
						label='Reason for transfer'>
						<Input
							name={'blkPaymentReason'}
							onChange={handleChange}
							className='rounded-[8px] h-[44px]'
						/>
					</Form.Item>
				</>
			),
		},
		{
			title: 'Last',
			key: 'Last',
			content: (
				<div>
					<p className='text-[15px] text-[#000] font-[600] mb-8'>
						Would you like to make the following transfer?
					</p>

					<p className='text-[#1D191B] text-[14px] font-[600] mb-3'>
						Payment Source:{' '}
						<span className='font-[400]'>
							{' '}
							{srcName?.walDesc} - {srcName?.walType}
						</span>
					</p>
					<p className='text-[#1D191B] text-[14px] font-[600] mb-3'>
						Amount:{' '}
						<span className='font-[400]'> KES {data?.blkPaymentAmount}</span>
					</p>
					<p className='text-[#1D191B] text-[14px] font-[600] mb-3'>
						Bank Name:{' '}
						<span className='font-[400]'> {data?.blkBeneficiaryBank}</span>
					</p>
					<p className='text-[#1D191B] text-[14px] font-[600] mb-3'>
						Branch:{' '}
						<span className='font-[400]'> {data?.blkBeneficiaryBranch}</span>
					</p>
					<p className='text-[#1D191B] text-[14px] font-[600] mb-3'>
						Account No:{' '}
						<span className='font-[400]'> {data?.blkBeneficiaryAccount}</span>
					</p>
					<p className='text-[#1D191B] text-[14px] font-[600] mb-3'>
						Account Name:{' '}
						<span className='font-[400]'> {data?.blkBeneficiaryDetails}</span>
					</p>
					<p className='text-[#1D191B] text-[14px] font-[600] mb-3'>
						Reason:{' '}
						<span className='font-[400]'> {data?.blkPaymentReason}</span>
					</p>
				</div>
			),
		},
		{
			title: 'Second',
			content: (
				<div className='flex flex-col justify-center mt-10'>
					<p className='text-center mb-10'>
						Enter the 4 digit code sent to you
					</p>
					<OTPForm handleOtpVerified={handleOtpVerified} />
				</div>
			),
		},
		{
			title: 'Processing',
			key: 'Processing',
			content: (
				<div className='max-h-fit h-auto'>
					{isSuccess ? (
						<>
							<div className='flex flex-col mt-[10%] border-2 border-[#3BBB6E] p-3'>
								<div className='flex items-center'>
									<TaskAltIcon className='text-[#3BBB6E]' />
									<p className='ml-2 text-[#0A0A0A] text-[16px] font-[600]'>
										Request successful
									</p>
								</div>
								<p className='text-[13px] text-[#404040] font[600] ml-[32px]'>
									{response}
								</p>
							</div>
						</>
					) : (
						<>
							<div className='flex flex-col mt-[10%] border-2 border-[red] p-3'>
								<div className='flex items-center'>
									<HighlightOffIcon className='text-[red]' />
									<p className='ml-2 text-[#0A0A0A] text-[16px] font-[600]'>
										Request Failed
									</p>
								</div>
								<p className='text-[13px] text-[#404040] font[600] ml-[32px]'>
									{response}
								</p>
							</div>
						</>
					)}

					<div className='flex justify-center mt-10'>
						{isSuccess ? (
							<Button
								style={{
									background: '#10101E',
									color: '#fff',
									fontWeight: 500,
									fontSize: '14px',
									marginLeft: '10px',
								}}
								onClick={() => handleDone()}>
								Done
							</Button>
						) : (
							<>
								<div className='flex items-center'>
									<Button
										disabled={payLoading}
										style={{
											background: '#D3D3D9',
											color: '#212143',
											fontWeight: 500,
											fontSize: '14px',
											marginLeft: '10px',
										}}
										onClick={() => handleDone()}>
										Close
									</Button>

									<Button
										disabled={payLoading}
										style={{
											background: '#10101E',
											color: '#fff',
											fontWeight: 500,
											fontSize: '14px',
											marginLeft: '10px',
										}}
										onClick={() => handleReset()}>
										Retry
									</Button>
								</div>
							</>
						)}
					</div>
				</div>
			),
		},
	];

	const items = steps.map((item) => ({
		key: item.title,
		title: item.title,
	}));

	return (
		<>
			<Modal
				title={
					current === 0
						? 'Direct Payment'
						: current === 1
						? 'Verification'
						: 'Transfer Status'
				}
				centered
				footer={null}
				open={open}
				onOk={() => next()}
				okText={current === 2 ? 'Transfer' : 'Next'}
				onCancel={() => handleCancel()}
				width={'639px'}
				cancelButtonProps={{
					style: {
						background: '#D3D3D9',
						color: '#212143',
						fontWeight: 500,
						fontSize: '14px',
					},
				}}
				okButtonProps={{
					style: {
						background: '#10101E',
						color: '#fff',
						fontWeight: 500,
						fontSize: '14px',
					},
				}}>
				<>
					<Form
						layout='vertical'
						ref={formRef}
						name='control-ref'
						onFinish={onFinish}
						style={{
							maxWidth: 600,
						}}
						form={form}>
						<Steps current={current} items={items} />
						<div style={contentStyle}>{steps[current].content}</div>

						{current === 3 ? null : (
							<div className='flex justify-end mt-[10%]'>
								{current > 0 && (
									<Button
										disabled={payLoading}
										style={{
											background: '#D3D3D9',
											color: '#212143',
											fontWeight: 500,
											fontSize: '14px',
										}}
										onClick={() => prev()}>
										{payLoading ? <Spin /> : 'Previous'}
									</Button>
								)}

								{current < steps.length - 1 && current !== 2 && (
									<Button
										disabled={payLoading}
										style={{
											background: '#10101E',
											color: '#fff',
											fontWeight: 500,
											fontSize: '14px',
											marginLeft: '10px',
										}}
										onClick={() => next()}>
										{payLoading ? <Spin /> : 'Next'}
									</Button>
								)}

								{current === 2 && (
									<Button
										disabled={payLoading}
										style={{
											background: '#10101E',
											color: '#fff',
											fontWeight: 500,
											fontSize: '14px',
											marginLeft: '10px',
										}}
										type='primary'
										htmlType='submit'>
										{payLoading ? <Spin /> : 'Transfer'}
									</Button>
								)}
							</div>
						)}
					</Form>
				</>
			</Modal>
		</>
	);
};

export default PayFundModalOne;
