import React, { useEffect, useState } from 'react';
import {
	Form,
	Input,
	Select,
	Modal,
	Steps,
	theme,
	Button,
	message,
	Spin,
} from 'antd';
import OTPForm from '../../../components/global/OTPForm';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { useDispatch, useSelector } from 'react-redux';
import {
	fetchCollectionTransfer,
	saveCollection,
} from '../../../features/collections/collectionSlice';
import { sendTransOtp } from '../../../features/global/globalSlice';
import { verifyTransOtp } from '../../../features/auth/authSlice';
import {
	fetchBranchWallets,
	fetchWallets,
} from '../../../features/wallet/walletSlice';
import { fetchBranches } from '../../../features/branch/branchSlice';

const { Option } = Select;

const PayFundModal = ({ open, closeModalOne, prefetchData }) => {
	const dispatch = useDispatch();
	const [form] = Form.useForm();

	const { disbursementWallets, branchWallets, walletLoading } = useSelector(
		(state) => state.wallet,
	);
	const { branches, branchLoading } = useSelector((state) => state.branch);
	const { user } = useSelector((state) => state.auth);
	const { colLoading } = useSelector((state) => state.collection);
	const { mainBranch } = useSelector((state) => state.global);
	const formRef = React.useRef(null);

	const [current, setCurrent] = useState(0);
	const [data, setdata] = useState({});
	const [otpVerified, setotpVerified] = useState('');
	const [wall, setwall] = useState(disbursementWallets);
	const [response, setresponse] = useState('');
	const [isSuccess, setisSuccess] = useState(false);
	const [orgBranches, setorgBranches] = useState(branches);
	const [activeBranch, setactiveBranch] = useState(orgBranches[0]);
	const [brnWallets, setbrnWallets] = useState(branchWallets);

	const usrObj = {
		usrId: user?.usrId,
		waltType: 'DISBURSEMENT',
		orgId: user?.orgId,
		brnId: activeBranch?.brnId,
	};

	async function handleFetchWallets() {
		const res = await dispatch(fetchWallets(usrObj));
		if (res?.payload?.success) {
			const resp = res.payload?.data?.result?.filter(
				(item) => item.walType === 'DISBURSEMENT',
			);
			setwall(resp);
		}
	}

	async function handleFetchBranches() {
		const res = await dispatch(fetchBranches(usrObj));
		if (res?.payload?.success) {
			setorgBranches(res.payload?.data?.result);
		}
	}

	function handleOtpVerified(val) {
		setotpVerified(val);
	}

	const next = async () => {
		const otpObj = {
			usrId: user?.usrId,
		};

		if (current === 0) {
			if (
				data?.waltTo === undefined ||
				data?.waltFrom === undefined ||
				data?.waltAmount === undefined ||
				data?.waltTransferReason === undefined
			) {
				return message.info('Please fill in the required fields');
			} else {
				setCurrent(current + 1);
			}
		} else if (current === 1) {
			const res = await dispatch(sendTransOtp(otpObj));
			if (res?.payload?.success) {
				setCurrent(current + 1);
			} else {
				message.info('We cannot complete your request at the moment');
			}
		}
	};

	const prev = () => {
		setCurrent(current - 1);
	};

	const contentStyle = {};

	async function handleDone() {
		setCurrent(0);
		await form.resetFields();
		await setdata({});
		closeModalOne();
	}

	function handleReset() {
		setCurrent(0);
	}

	function handleSelectChange(value) {
		setdata((prevData) => ({
			...prevData,
			waltFrom: value,
		}));
	}

	function handleSelectChange2(value) {
		setdata((prevData) => ({
			...prevData,
			waltTo: value,
		}));
	}

	const handleChange = (e) => {
		setdata((prevData) => ({
			...prevData,
			[e.target.name]: e.target.value,
		}));
	};

	let srcName = disbursementWallets?.find(
		(item) => item?.walId == data?.waltFrom,
	);

	let benName;

	if (mainBranch) {
		benName = branchWallets?.find((item) => item?.walId == data?.waltTo);
	} else {
		benName = disbursementWallets?.find((item) => item?.walId == data?.waltTo);
	}

	const onFinish = async () => {
		data.waltCreatedBy = user?.usrId;
		data.waltType = 'DISBURSEMENT';
		data.waltMethod = 'OUTGOING';
		data.waltDesc = 'NDOVUPAY';

		data.waltFromWalCode = srcName.walCode;
		data.waltToWalCode = benName.walCode;
		data.waltFromWalName = `${srcName?.walDesc} - ${srcName?.walType}`;
		data.waltToWalName = `${benName?.walDesc} - ${benName?.walType}`;

		if (data.waltTo === data.waltFrom) {
			return message.info('You cannot transfer to the same account');
		}

		const otpObj = {
			usrId: user?.usrId,
			usrSalt: otpVerified,
		};

		if (otpVerified?.length === 4) {
			const resp = await dispatch(verifyTransOtp(otpObj));
			if (resp?.payload?.success) {
				const res = await dispatch(saveCollection(data));
				if (res?.payload?.success) {
					setisSuccess(true);
					setresponse(res?.payload?.messages?.message);
					const resp = await dispatch(fetchCollectionTransfer(usrObj));
					if (resp?.payload?.success) {
						prefetchData();
					}
				} else {
					setisSuccess(false);
					setresponse(res?.payload?.messages?.message);
				}
				await setCurrent(3);
			} else {
				return message.error('Could not verify code. Please try again');
			}
		} else {
			return message.info('Enter the four digit verification code');
		}
	};

	async function handleBranchChange(brnId) {
		const brnObj = {
			usrId: user?.usrId,
			brnId: brnId,
		};
		const res = await dispatch(fetchBranchWallets(brnObj));

		if (res?.payload?.success) {
			const resp = res.payload?.data?.result?.filter(
				(item) => item.walType === 'DISBURSEMENT',
			);
			setbrnWallets(resp);
		}
		let actvBrn = orgBranches?.find(item => item?.brnId === brnId)
		setactiveBranch(actvBrn)
	}

	async function handleBranchData() {
		const res = await dispatch(fetchBranchWallets(usrObj));
		if (res?.payload?.success) {
			const resp = res.payload?.data?.result?.filter(
				(item) => item.walType === 'DISBURSEMENT',
			);
			setbrnWallets(resp);
		}
	}

	async function handleCancel() {
		await form.resetFields();
		setdata({});
		closeModalOne();
	}

	const steps = [
		{
			title: 'Payment Info',
			key: 'Payment Info',
			content: (
				<>
					<div className=' mb-4'>
						<label className='mb-1 flex items-center' for='waltFrom'>
							<span className='text-[#ff4d4f] text-[21px] mr-1 mt-[5px]'>
								*
							</span>{' '}
							Payment Source
						</label>
						<Select
							onDropdownVisibleChange={handleFetchWallets}
							loading={walletLoading}
							name={'waltFrom'}
							defaultValue='Choose'
							style={{
								width: '100%',
							}}
							onChange={(e) => handleSelectChange(e)}
						>
							{wall?.map((item) => {
								return (
									<>
										<Option
											value={item?.walId}
										>{`${item?.walDesc} - ${item?.walType} (KES ${item?.walAmount})`}</Option>
									</>
								);
							})}
						</Select>
					</div>

					{mainBranch ? (
						<>
							<div className=' mb-4'>
								<label className='mb-1 flex items-center' for='waltTo'>
									<span className='text-[#ff4d4f] text-[21px] mr-1 mt-[5px]'>
										*
									</span>{' '}
									Beneficiary Account
								</label>
								<div className='flex items-center'>
									{orgBranches?.length > 1 ? (
										<Select
											loading={branchLoading}
											onDropdownVisibleChange={handleFetchBranches}
											onChange={(e) => handleBranchChange(e)}
											defaultValue='Select Branch'
											style={{
												width: '190px',
												marginRight: 20,
											}}
										>
											{orgBranches?.map((item) => {
												return (
													<>
														<Option value={item?.brnId}>{item?.brnName}</Option>
													</>
												);
											})}
										</Select>
									) : null}

									<Select
										loading={walletLoading}
										onDropdownVisibleChange={handleBranchData}
										name={'waltTo'}
										defaultValue='Choose'
										style={{
											width: '100%',
										}}
										onChange={(e) => handleSelectChange2(e)}
									>
										{brnWallets?.map((item) => {
											return (
												<>
													<Option
														value={item?.walId}
													>{`${item?.walDesc} - ${item?.walType} (KES ${item?.walAmount})`}</Option>
												</>
											);
										})}
									</Select>
								</div>
							</div>
						</>
					) : (
						<>
							<div className=' mb-4'>
								<label className='mb-1 flex items-center' for='waltTo'>
									<span className='text-[#ff4d4f] text-[21px] mr-1 mt-[5px]'>
										*
									</span>{' '}
									Beneficiary Account
								</label>

								<Select
									loading={walletLoading}
									onDropdownVisibleChange={handleFetchWallets}
									name={'waltTo'}
									defaultValue='Choose'
									style={{
										width: '100%',
									}}
									onChange={(e) => handleSelectChange2(e)}
								>
									{wall?.map((item) => {
										return (
											<>
												<Option
													value={item?.walId}
												>{`${item?.walDesc} - ${item?.walType} (KES ${item?.walAmount})`}</Option>
											</>
										);
									})}
								</Select>
							</div>
						</>
					)}

					<Form.Item
						rules={[
							{
								required: true,
								message: 'Enter amount',
							},
						]}
						name={'waltAmount'}
						label='Amount (KES)'
					>
						<Input
							onChange={handleChange}
							name={'waltAmount'}
							type='number'
							className='rounded-[8px] h-[44px]'
						/>
					</Form.Item>

					<Form.Item
						required
						name={'waltTransferReason'}
						label='Reason for transfer'
					>
						<Input
							name={'waltTransferReason'}
							onChange={handleChange}
							className='rounded-[8px] h-[44px]'
						/>
					</Form.Item>
				</>
			),
		},
		{
			title: 'Last',
			key: 'Last',
			content: (
				<div>
					<p className='text-[15px] text-[#000] font-[600] mb-8'>
						Would you like to make the following transfer?
					</p>

					<p className='text-[#1D191B] text-[14px] font-[600] mb-3'>
						Payment Source:{' '}
						<span className='font-[400]'>
							{' '}
							{srcName?.walDesc} - {srcName?.walType}
						</span>
					</p>
					<p className='text-[#1D191B] text-[14px] font-[600] mb-3'>
						Beneficiary Account:{' '}
						<span className='font-[400]'>
							{' '}
							{benName?.walDesc} - {benName?.walType}
						</span>
					</p>

					<p className='text-[#1D191B] text-[14px] font-[600] mb-3'>
						Amount: <span className='font-[400]'> KES {data?.waltAmount}</span>
					</p>
					<p className='text-[#1D191B] text-[14px] font-[600] mb-3'>
						Reason:{' '}
						<span className='font-[400]'> {data?.waltTransferReason}</span>
					</p>
				</div>
			),
		},
		{
			title: 'Second',
			content: (
				<div className='flex flex-col justify-center mt-10'>
					<p className='text-center mb-10'>
						Enter the 4 digit code sent to you
					</p>
					<OTPForm handleOtpVerified={handleOtpVerified} />
				</div>
			),
		},
		{
			title: 'Processing',
			key: 'Processing',
			content: (
				<div className='max-h-fit h-auto'>
					{isSuccess ? (
						<>
							<div className='flex flex-col mt-[10%] border-2 border-[#3BBB6E] p-3'>
								<div className='flex items-center'>
									<TaskAltIcon className='text-[#3BBB6E]' />
									<p className='ml-2 text-[#0A0A0A] text-[16px] font-[600]'>
										Request successful
									</p>
								</div>
								<p className='text-[13px] text-[#404040] font[600] ml-[32px]'>
									{response}
								</p>
							</div>
						</>
					) : (
						<>
							<div className='flex flex-col mt-[10%] border-2 border-[red] p-3'>
								<div className='flex items-center'>
									<HighlightOffIcon className='text-[red]' />
									<p className='ml-2 text-[#0A0A0A] text-[16px] font-[600]'>
										Request Failed
									</p>
								</div>
								<p className='text-[13px] text-[#404040] font[600] ml-[32px]'>
									{response}
								</p>
							</div>
						</>
					)}

					<div className='flex justify-center mt-10'>
						{isSuccess ? (
							<Button
								style={{
									background: '#10101E',
									color: '#fff',
									fontWeight: 500,
									fontSize: '14px',
									marginLeft: '10px',
								}}
								onClick={() => handleDone()}
							>
								Done
							</Button>
						) : (
							<>
								<div className='flex items-center'>
									<Button
										style={{
											background: '#D3D3D9',
											color: '#212143',
											fontWeight: 500,
											fontSize: '14px',
											marginLeft: '10px',
										}}
										onClick={() => handleDone()}
									>
										Close
									</Button>

									<Button
										style={{
											background: '#10101E',
											color: '#fff',
											fontWeight: 500,
											fontSize: '14px',
											marginLeft: '10px',
										}}
										onClick={() => handleReset()}
									>
										Retry
									</Button>
								</div>
							</>
						)}
					</div>
				</div>
			),
		},
	];

	const items = steps.map((item) => ({
		key: item.title,
		title: item.title,
	}));

	useEffect(() => {}, [
		otpVerified,
		data,
		activeBranch,
		wall,
		brnWallets,
		orgBranches,
	]);

	useEffect(() => {
		setwall(disbursementWallets);
	}, [disbursementWallets]);

	useEffect(() => {
		setorgBranches(branches);
	}, [branches]);

	useEffect(() => {
		setbrnWallets(branchWallets);
	}, [branchWallets]);

	useEffect(() => {
		handleFetchBranches();
	}, []);

	return (
		<>
			<Modal
				title={
					current === 0
						? 'Funds Transfer'
						: current === 1
						? 'Verification'
						: 'Transfer Status'
				}
				centered
				footer={null}
				open={open}
				onOk={() => next()}
				okText={current === 2 ? 'Transfer' : 'Next'}
				onCancel={() => handleCancel()}
				width={'639px'}
				cancelButtonProps={{
					style: {
						background: '#D3D3D9',
						color: '#212143',
						fontWeight: 500,
						fontSize: '14px',
					},
				}}
				okButtonProps={{
					style: {
						background: '#10101E',
						color: '#fff',
						fontWeight: 500,
						fontSize: '14px',
					},
				}}
			>
				<>
					<Form
						layout='vertical'
						ref={formRef}
						name='control-ref'
						onFinish={onFinish}
						style={{
							maxWidth: 600,
						}}
						form={form}
					>
						<Steps current={current} items={items} />
						<div style={contentStyle}>{steps[current].content}</div>

						{current === 3 ? null : (
							<div className='flex justify-end mt-[10%]'>
								{current > 0 && (
									<Button
										style={{
											background: '#D3D3D9',
											color: '#212143',
											fontWeight: 500,
											fontSize: '14px',
										}}
										onClick={() => prev()}
									>
										Previous
									</Button>
								)}

								{current < steps.length - 1 && current !== 2 && (
									<Button
										style={{
											background: '#10101E',
											color: '#fff',
											fontWeight: 500,
											fontSize: '14px',
											marginLeft: '10px',
										}}
										onClick={() => next()}
									>
										{colLoading ? <Spin /> : 'Next'}
									</Button>
								)}

								{current === 2 && (
									<Button
										style={{
											background: '#10101E',
											color: '#fff',
											fontWeight: 500,
											fontSize: '14px',
											marginLeft: '10px',
										}}
										htmlType='submit'
										type='primary'
									>
										{colLoading ? <Spin /> : 'Transfer'}
									</Button>
								)}
							</div>
						)}
					</Form>
				</>
			</Modal>
		</>
	);
};

export default PayFundModal;
