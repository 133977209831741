import React, { useEffect, useState } from 'react';
import {
	Form,
	Input,
	Select,
	Modal,
	Steps,
	Button,
	InputNumber,
	Spin,
} from 'antd';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import { useDispatch, useSelector } from 'react-redux';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import {
	fetchSettlementAccounts,
	saveSettlementAccount,
} from '../../../features/organization/organizationSlice';
import { settlementType } from '../../../data';
import { fetchBanks } from '../../../features/payment/paymentSlice';

const SettlementModal = ({ open3, closeModalOne, handleUpdateState }) => {
	const dispatch = useDispatch();
	const [form] = Form.useForm();
	const formRef = React.useRef(null);

	const { disbursementWallets, walletLoading } = useSelector(
		(state) => state.wallet
	);
	const { user } = useSelector((state) => state.auth);
	const { banks, payLoading } = useSelector((state) => state.payment);

	const [current, setCurrent] = useState(0);
	const [statedisbursementWallets, setstatedisbursementWallets] =
		useState(disbursementWallets);
	const [response, setresponse] = useState('');
	const [isSuccess, setisSuccess] = useState(false);
	const [data, setdata] = useState({
		settOrgId: user?.orgId,
		settBrnId: user?.brnId,
	});

	const next = async () => {
		setCurrent(current + 1);
	};

	const prev = () => {
		setCurrent(current - 1);
	};

	const contentStyle = {};

	function handleDone() {
		setCurrent(0);
		form.resetFields();
		setdata({});
		closeModalOne();
	}

	function handleReset() {
		setCurrent(0);
	}

	const handleChange = (e) => {
		setdata((prevData) => ({
			...prevData,
			[e.target.name]: e.target.value,
		}));
	};

	async function handleFetchbanks() {
		const usrObj = {
			usrId: user?.usrId,
		};

		await dispatch(fetchBanks(usrObj));
	}

	const onFinish = async () => {
		const res = await dispatch(saveSettlementAccount(data));
		if (res?.payload?.success) {
			await setisSuccess(true);
			await setresponse('Settlement account added successfully');
			await next();
			await dispatch(fetchSettlementAccounts());
		} else {
			await setisSuccess(false);
			await setresponse(res?.payload?.messages?.message);
			await next();
		}
	};

	async function handleCancel() {
		await form.resetFields();
		setdata({});
		closeModalOne();
	}

	function handleFieldChange(value, fileName) {
		setdata((prevData) => ({
			...prevData,
			[fileName]: value,
		}));
	}

	const steps = [
		{
			title: 'Payment Info',
			key: 'Payment Info',
			content: (
				<>
					<Form.Item
						rules={[
							{
								required: true,
								message: 'Select account type',
							},
						]}
						name={'settType'}
						label='Account type'>
						<Select
							value={data?.pbType}
							loading={walletLoading}
							defaultValue='Choose'
							style={{
								width: '100%',
								height: '44px',
							}}
							onChange={(e) => handleFieldChange(e, 'settType')}
							options={settlementType.map((item) => {
								return {
									label: item.label,
									value: item.value,
								};
							})}
						/>
					</Form.Item>

					{data?.settType === 'PAYBILL' ? (
						<>
							<Form.Item
								rules={[
									{
										required: true,
										message: 'Add name',
									},
								]}
								name={'settName'}
								label='Account name'>
								<Input
									name={'settName'}
									onChange={handleChange}
									className='rounded-[8px] h-[44px]'
								/>
							</Form.Item>

							<Form.Item
								rules={[
									{
										required: true,
										message: 'Enter number',
									},
								]}
								name={'settBank'}
								label='Paybill'>
								<InputNumber
									minLength={4}
									onChange={(e) => handleFieldChange(e, 'settBank')}
									name={'settBank'}
									className='rounded-[8px] h-[44px] w-full'
								/>
							</Form.Item>

							<Form.Item
								rules={[
									{
										required: true,
										message: 'Enter number',
									},
								]}
								name={'settAccount'}
								label='Account number'>
								<Input
									minLength={4}
									onChange={handleChange}
									name={'settAccount'}
									className='rounded-[8px] h-[44px] w-full'
								/>
							</Form.Item>
						</>
					) : data?.settType === 'BANK' ? (
						<>
							<Form.Item
								rules={[
									{
										required: true,
										message: 'Enter number',
									},
								]}
								name={'settBank'}
								label='Bank'>
								<Select
									loading={payLoading}
									defaultValue='Choose'
									style={{
										width: '100%',
									}}
									onChange={(e) => handleFieldChange(e, 'settBank')}
									options={banks?.map((item) => {
										return {
											label: item.bnkName,
											value: item.bnkCode,
										};
									})}
								/>
							</Form.Item>

							<Form.Item
								rules={[
									{
										required: true,
										message: 'Add name',
									},
								]}
								name={'settName'}
								label='Account name'>
								<Input
									name={'settName'}
									onChange={handleChange}
									className='rounded-[8px] h-[44px]'
								/>
							</Form.Item>

							<Form.Item
								rules={[
									{
										required: true,
										message: 'Enter number',
									},
								]}
								name={'settAccount'}
								label='Account number'>
								<Input
									minLength={4}
									onChange={handleChange}
									name={'settAccount'}
									className='rounded-[8px] h-[44px] w-full'
								/>
							</Form.Item>
						</>
					) : null}
				</>
			),
		},
		{
			title: 'Processing',
			key: 'Processing',
			content: (
				<div className='max-h-fit h-auto'>
					{isSuccess ? (
						<>
							<div className='flex flex-col mt-[10%] border-2 border-[#3BBB6E] p-3'>
								<div className='flex items-center'>
									<TaskAltIcon className='text-[#3BBB6E]' />
									<p className='ml-2 text-[#0A0A0A] text-[16px] font-[600]'>
										Request successful
									</p>
								</div>
								<p className='text-[13px] text-[#404040] font[600] ml-[32px]'>
									{response}
								</p>
							</div>
						</>
					) : (
						<>
							<div className='flex flex-col mt-[10%] border-2 border-[red] p-3'>
								<div className='flex items-center'>
									<HighlightOffIcon className='text-[red]' />
									<p className='ml-2 text-[#0A0A0A] text-[16px] font-[600]'>
										Request Failed
									</p>
								</div>
								<p className='text-[13px] text-[#404040] font[600] ml-[32px]'>
									{response}
								</p>
							</div>
						</>
					)}

					<div className='flex justify-center mt-10'>
						{isSuccess ? (
							<Button
								style={{
									background: '#10101E',
									color: '#fff',
									fontWeight: 500,
									fontSize: '14px',
									marginLeft: '10px',
								}}
								onClick={() => handleDone()}>
								Done
							</Button>
						) : (
							<>
								<div className='flex items-center'>
									<Button
										style={{
											background: '#D3D3D9',
											color: '#212143',
											fontWeight: 500,
											fontSize: '14px',
											marginLeft: '10px',
										}}
										onClick={() => handleDone()}>
										Close
									</Button>

									<Button
										style={{
											background: '#10101E',
											color: '#fff',
											fontWeight: 500,
											fontSize: '14px',
											marginLeft: '10px',
										}}
										onClick={() => handleReset()}>
										Retry
									</Button>
								</div>
							</>
						)}
					</div>
				</div>
			),
		},
	];

	const items = steps.map((item) => ({
		key: item.title,
		title: item.title,
	}));

	useEffect(() => {
		handleFetchbanks();
	}, []);

	useEffect(() => {}, [data, statedisbursementWallets]);

	useEffect(() => {
		setstatedisbursementWallets(disbursementWallets);
	}, [disbursementWallets]);

	return (
		<>
			<Modal
				title={current === 0 ? 'Add Settlement Account' : 'Status'}
				centered
				footer={null}
				open={open3}
				onOk={() => next()}
				okText={'Submit'}
				onCancel={() => handleCancel()}
				width={'639px'}
				cancelButtonProps={{
					style: {
						background: '#D3D3D9',
						color: '#212143',
						fontWeight: 500,
						fontSize: '14px',
					},
				}}
				okButtonProps={{
					style: {
						background: '#10101E',
						color: '#fff',
						fontWeight: 500,
						fontSize: '14px',
					},
				}}>
				<>
					<Form
						layout='vertical'
						ref={formRef}
						name='control-ref'
						onFinish={onFinish}
						style={{
							maxWidth: 600,
						}}
						form={form}>
						<Steps current={current} items={items} />
						<div style={contentStyle}>{steps[current].content}</div>

						{current === 3 ? null : (
							<div className='flex justify-end mt-[10%]'>
								{current > 0 && (
									<Button
										style={{
											background: '#D3D3D9',
											color: '#212143',
											fontWeight: 500,
											fontSize: '14px',
										}}
										onClick={() => prev()}>
										{payLoading ? <Spin /> : 'Previous'}
									</Button>
								)}

								{current < steps.length - 1 && current !== 2 && (
									<Button
										style={{
											background: '#10101E',
											color: '#fff',
											fontWeight: 500,
											fontSize: '14px',
											marginLeft: '10px',
										}}
										htmlType='submit'>
										{payLoading ? <Spin /> : 'Submit'}
									</Button>
								)}
							</div>
						)}
					</Form>
				</>
			</Modal>
		</>
	);
};

export default SettlementModal;
