import React, { useEffect, useState } from 'react';
import {
	Form,
	Input,
	Select,
	Modal,
	Steps,
	Button,
	message,
	Checkbox,
} from 'antd';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import { useDispatch, useSelector } from 'react-redux';
import {
	closeSelectedActive,
	fetchUserPermissions,
	fetchUsersAdmin,
	updateUser,
	updateUserPermissions,
} from '../../../features/auth/authSlice';
import { fetchRoot } from '../../../features/nav/navSlice';
import toast from 'react-hot-toast';
import { permissionsArray } from '../../../data';

const EditUserModal = ({ open, closeModalOne }) => {
	const formRef = React.useRef(null);
	const [form] = Form.useForm();
	const { activeUser } = useSelector((state) => state.auth);
	const dispatch = useDispatch();
	const { user, permissions } = useSelector((state) => state.auth);

	const [current, setCurrent] = useState(0);
	const [formData, setformData] = useState(activeUser);

	const [perm, setperm] = useState(null);

	async function handleDefaultLoading() {
		const usrObj = {
			usrId: activeUser?.usrId,
			app: 0,
		};
		await dispatch(fetchUserPermissions(usrObj));
	}

	async function handlePermissionChange(val) {
		const usrObj = {
			usrId: activeUser?.usrId,
			app: val,
		};
		await setperm(val);
		await dispatch(fetchUserPermissions(usrObj));
	}

	const onChange = async (e) => {
		let chk = e.target.checked;
		const data = {
			rolesId: e.target.name,
			userId: activeUser?.usrId,
			nodeValue: chk ? true : false,
		};

		const usrObj = {
			usrId: activeUser?.usrId,
			app: perm,
		};

		await dispatch(updateUserPermissions(data));

		await dispatch(fetchUserPermissions(usrObj));
	};

	const next = () => {
		setCurrent(current + 1);
	};

	const contentStyle = {};

	function handleDone() {
		setCurrent(0);
		closeModalOne();
	}

	function handleCancel() {
		dispatch(closeSelectedActive());
		closeModalOne();
	}

	async function handleDisableUser() {
		const values = {};
		values.usrId = activeUser?.usrId;
		values.usrEnabled = 0;

		const res = await dispatch(updateUser(values));
		if (res?.payload?.usrId) {
			await dispatch(fetchUsersAdmin(user));
			toast.success('Role updated');
		}
	}

	async function handleUpdateUserRole(val) {
		const values = {};
		values.usrId = activeUser?.usrId;
		values.usrRoleId = val;

		const res = await dispatch(updateUser(values));
		if (res?.payload?.usrId) {
			await dispatch(fetchUsersAdmin(user));
			toast.success('User status updated');
		}
	}

	async function handleEnableUser() {
		const values = {};
		values.usrId = activeUser?.usrId;
		values.usrEnabled = 1;

		const res = await dispatch(updateUser(values));
		if (res?.payload?.usrId) {
			await dispatch(fetchUsersAdmin(user));
			toast.success('User status updated');
		}
	}

	const onFinish = async () => {
		setCurrent(1);
		await dispatch(fetchUsersAdmin(user));
		const data = {
			usrId: user?.usrId,
		};
		await dispatch(fetchRoot(data));
	};

	const steps = [
		{
			title: 'User Info',
			key: 'User Info',
			content: (
				<>
					<Form.Item name='usrFullNames' label='Name'>
						<Input disabled className='rounded-[8px] h-[44px]' />
					</Form.Item>

					<Form.Item name='usrRoleId' label='Role'>
						<Select
							onChange={handleUpdateUserRole}
							defaultValue={Number(activeUser?.usrRoleId)}
							style={{
								width: '100%',
							}}
							options={[
								{
									value: 2,
									label: 'Regular',
								},
								{
									value: 1,
									label: 'Admin',
								},
								{
									value: 0,
									label: 'Branches Operator',
								},
							]}
						/>
					</Form.Item>

					<div className='flex items-center w-full'>
						<Form.Item name='usrPermissions' label='Permission'>
							<Select
								onChange={handlePermissionChange}
								defaultValue='Select permissions'
								style={{
									width: '290px',
								}}
								options={permissionsArray}
							/>
						</Form.Item>

						<>
							{permissions.length
								? permissions?.map((item) => {
										return (
											<>
												<Checkbox
													className='ml-5'
													checked={item?.checked}
													name={item?.id}
													onChange={onChange}
												>
													{item?.text}
												</Checkbox>
											</>
										);
								  })
								: null}
						</>
					</div>

					<Form.Item name='usrEmail' label='Email'>
						<Input
							disabled
							// value={activeUser?.usrEmail}
							className='rounded-[8px] h-[44px]'
						/>
					</Form.Item>

					<Form.Item name='usrMobileNumber' label='Phone No'>
						<Input
							disabled
							// value={activeUser?.usrMobileNumber}
							className='rounded-[8px] h-[44px]'
						/>
					</Form.Item>
				</>
			),
		},
		{
			title: 'Processing',
			key: 'Processing',
			content: (
				<div className='h-auto'>
					<div className='flex flex-col mt-[10%] border-2 border-[#3BBB6E] p-3'>
						<div className='flex items-center'>
							<TaskAltIcon className='text-[#3BBB6E]' />
							<p className='ml-2 text-[#0A0A0A] text-[16px] font-[600]'>
								Changes Updated
							</p>
						</div>
						<p className='text-[13px] text-[#404040] font[600] ml-[32px]'>
							Changes made will reflect on the next login session
						</p>
					</div>

					<div className='flex justify-center mt-10'>
						<Button
							style={{
								background: '#10101E',
								color: '#fff',
								fontWeight: 500,
								fontSize: '14px',
								marginLeft: '10px',
							}}
							onClick={() => handleDone()}
						>
							Done
						</Button>
					</div>
				</div>
			),
		},
	];

	const items = steps.map((item) => ({
		key: item.title,
		title: item.title,
	}));

	useEffect(() => {
		form.setFieldsValue({
			usrMobileNumber: activeUser?.usrMobileNumber,
			usrEmail: activeUser?.usrEmail,
			usrFullNames: activeUser?.usrFullNames,
			usrRoleId: activeUser?.usrRoleId,
		});
	}, [activeUser]);

	useEffect(() => {
		setformData(activeUser);
	}, [perm, permissions]);

	useEffect(() => {
		setformData(activeUser);
		handleDefaultLoading();
	}, []);

	return (
		<>
			<Modal
				title={current === 0 ? 'Manage User' : 'Done'}
				centered
				footer={null}
				open={open}
				onOk={() => next()}
				okText={'Save'}
				onCancel={() => handleCancel()}
				width={'639px'}
				cancelButtonProps={{
					style: {
						background: '#D3D3D9',
						color: '#212143',
						fontWeight: 500,
						fontSize: '14px',
					},
				}}
				okButtonProps={{
					style: {
						background: '#10101E',
						color: '#fff',
						fontWeight: 500,
						fontSize: '14px',
					},
				}}
			>
				<>
					<Form
						form={form}
						layout='vertical'
						ref={formRef}
						name='control-ref'
						onFinish={onFinish}
						style={{
							maxWidth: 600,
						}}
					>
						<Steps current={current} items={items} />
						<div style={contentStyle}>{steps[current].content}</div>

						{current === 0 ? (
							<>
								<div className='flex justify-end mt-[10%]'>
									<Button
										style={{
											background: '#D3D3D9',
											color: '#212143',
											fontWeight: 500,
											fontSize: '14px',
										}}
										onClick={() => handleCancel()}
									>
										Cancel
									</Button>

									<Button
										style={{
											background: '#10101E',
											color: '#fff',
											fontWeight: 500,
											fontSize: '14px',
											marginLeft: '10px',
										}}
										type='primary'
										htmlType='submit'
										// onClick={() => createUser()}
									>
										Finish
									</Button>
								</div>
								<div>
									{activeUser?.usrEnabled === 1 ? (
										<button
											onClick={handleDisableUser}
											className='text-[#920A0A] text-[14px] font-[400]'
											type='button'
										>
											Disable User
										</button>
									) : (
										<button
											onClick={handleEnableUser}
											className='text-[#3BBB6E] text-[14px] font-[400]'
											type='button'
										>
											Activate User Account
										</button>
									)}
								</div>
							</>
						) : null}
					</Form>
				</>
			</Modal>
		</>
	);
};

export default EditUserModal;
