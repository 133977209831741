import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const url = process.env.REACT_APP_API_BASE_URL;

const initialState = {
	orgLoading: false,
	organizations: [],
	orgPayAccounts: [],
	orgSysParams: [],
	settlementAccounts: [],
};

export const fetchOrganizations = createAsyncThunk(
	'organizationSlice/fetchOrganizations',
	(usrObj) => {
		return axios
			.get(`${url}/web/fetch_organization.action?usrId=${usrObj?.usrId}`)
			.then((res) => res.data);
	}
);

export const fetchOrganizationPayAccounts = createAsyncThunk(
	'organizationSlice/fetchOrganizationPayAccounts',
	(usrObj) => {
		return axios
			.get(
				`${url}/web/fetchOrgPaymentsDetails.action?usrId=${usrObj?.usrId}&orgadBrnId=${usrObj?.orgadBrnId}`
			)
			.then((res) => res.data);
	}
);

export const saveOrganizationPayAccounts = createAsyncThunk(
	'organizationSlice/saveOrganizationPayAccounts',
	(data) => {
		return axios
			.post(`${url}/web/saveOrgPaymentsDetails.action`, data)
			.then((res) => res.data);
	}
);

export const fetchOrganizationSysParams = createAsyncThunk(
	'organizationSlice/fetchOrganizationSysParams',
	(usrObj) => {
		return axios
			.get(
				`${url}/web/fetchSysParameter.action?usrId=${usrObj?.usrId}&prBrnId=${usrObj?.brnId}`
			)
			.then((res) => res.data);
	}
);

export const saveOrganizationSysParams = createAsyncThunk(
	'organizationSlice/saveOrganizationSysParams',
	(data) => {
		return axios
			.post(`${url}/web/saveSysParameter.action`, data)
			.then((res) => res.data);
	}
);

export const saveSettlementAccount = createAsyncThunk(
	'organizationSlice/saveSettlementAccount',
	(data) => {
		return axios
			.post(`${url}/stp/saveSettlementAccount.action`, data)
			.then((res) => res.data);
	}
);

export const fetchSettlementAccounts = createAsyncThunk(
	'organizationSlice/fetchSettlementAccounts',
	() => {
		return axios
			.get(`${url}/stp/fetchSettlementAccounts.action`)
			.then((res) => res.data.data.result);
	}
);

export const organizationSlice = createSlice({
	name: 'org',
	initialState,
	reducers: {
		orgCleanUp: (state) => {
			state.orgLoading = false;
			state.organizations = [];
			state.orgPayAccounts = [];
			state.orgSysParams = [];
			state.settlementAccounts = [];
		},
	},
	extraReducers: (builder) => {
		builder

			.addCase(fetchSettlementAccounts.pending, (state) => {
				state.orgLoading = true;
			})
			.addCase(fetchSettlementAccounts.fulfilled, (state, action) => {
				state.orgLoading = false;				
				state.settlementAccounts = action.payload;
			})
			.addCase(fetchSettlementAccounts.rejected, (state) => {
				state.orgLoading = false;
			})

			.addCase(saveSettlementAccount.pending, (state) => {
				state.orgLoading = true;
			})
			.addCase(saveSettlementAccount.fulfilled, (state, action) => {
				state.orgLoading = false;
			})
			.addCase(saveSettlementAccount.rejected, (state) => {
				state.orgLoading = false;
			})

			.addCase(fetchOrganizations.pending, (state) => {
				state.orgLoading = true;
			})
			.addCase(fetchOrganizations.fulfilled, (state, action) => {
				state.orgLoading = false;
				state.organizations = action.payload;
			})
			.addCase(fetchOrganizations.rejected, (state) => {
				state.orgLoading = false;
			})

			.addCase(fetchOrganizationPayAccounts.pending, (state) => {
				state.orgLoading = true;
			})
			.addCase(fetchOrganizationPayAccounts.fulfilled, (state, action) => {
				state.orgLoading = false;
				state.orgPayAccounts = action.payload?.data?.result;
			})
			.addCase(fetchOrganizationPayAccounts.rejected, (state) => {
				state.orgLoading = false;
			})

			.addCase(saveOrganizationPayAccounts.pending, (state) => {
				state.orgLoading = true;
			})
			.addCase(saveOrganizationPayAccounts.fulfilled, (state, action) => {
				state.orgLoading = false;
			})
			.addCase(saveOrganizationPayAccounts.rejected, (state) => {
				state.orgLoading = false;
			})

			.addCase(fetchOrganizationSysParams.pending, (state) => {
				state.orgLoading = true;
			})
			.addCase(fetchOrganizationSysParams.fulfilled, (state, action) => {
				state.orgLoading = false;
				if (action?.payload?.success) {
					state.orgSysParams = action?.payload?.data?.result;
				} else {
					state.orgSysParams = [];
				}
			})
			.addCase(fetchOrganizationSysParams.rejected, (state) => {
				state.orgLoading = false;
			})

			.addCase(saveOrganizationSysParams.pending, (state) => {
				state.orgLoading = true;
			})
			.addCase(saveOrganizationSysParams.fulfilled, (state) => {
				state.orgLoading = false;
			})
			.addCase(saveOrganizationSysParams.rejected, (state) => {
				state.orgLoading = false;
			});
	},
});

export default organizationSlice.reducer;
export const { orgCleanUp } = organizationSlice.actions;
