import React, { useEffect, useState } from 'react';
import {
	Form,
	Input,
	Select,
	Modal,
	Steps,
	Button,
	message,
	Spin,
	InputNumber,
} from 'antd';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import { useDispatch, useSelector } from 'react-redux';
import {
	fetchCollectionTransfer,
	initiateMpesaSTK,
} from '../../../features/collections/collectionSlice';
import { fetchWallets } from '../../../features/wallet/walletSlice';

const { Option } = Select;

const ColFundModalTwo = ({ open, closeModalOne, handleUpdateState }) => {
	const dispatch = useDispatch();
	const [form] = Form.useForm();
	const formRef = React.useRef(null);

	const { collectionWallets, walletLoading } = useSelector(
		(state) => state.wallet,
	);
	const { user } = useSelector((state) => state.auth);
	const { colLoading } = useSelector((state) => state.collection);
	const { globalBrnId } = useSelector((state) => state.global);

	const [current, setCurrent] = useState(0);
	const [data, setdata] = useState({});
	const [response, setresponse] = useState('');
	const [isSuccess, setisSuccess] = useState(false);
	const [colWal, setcolWal] = useState(collectionWallets);

	const usrObj = {
		usrId: user?.usrId,
		waltType: 'COLLECTION',
		orgId: user?.orgId,
		brnId: globalBrnId ?? user?.brnId,
	};

	async function handleFetchWallets() {
		const res = await dispatch(fetchWallets(usrObj));
		if (res?.payload?.success) {
			const resp = res.payload?.data?.result?.filter(
				(item) => item.walType === 'COLLECTION',
			);
			setcolWal(resp);
		}
	}

	const next = async () => {
		if (current === 0) {
			if (
				data?.phone === undefined ||
				data?.amount === undefined ||
				data?.accountref === undefined
			) {
				return message.info('Please fill in the required fields');
			} else {
				setCurrent(current + 1);
			}
		}
	};

	const prev = () => {
		setCurrent(current - 1);
	};

	const contentStyle = {};

	async function handleDone() {
		setCurrent(0);
		await form.resetFields();
		await setdata({});
		closeModalOne();
	}

	function handleReset() {
		setCurrent(0);
	}

	function handleSelectChange(value) {
		setdata((prevData) => ({
			...prevData,
			accountref: value,
		}));
	}

	const handleNumberChange = (e) => {
		setdata((prevData) => ({
			...prevData,
			phone: '254' + String(e),
		}));
	};

	const handleChange = (e) => {
		setdata((prevData) => ({
			...prevData,
			[e.target.name]: e.target.value,
		}));
	};

	let srcName = collectionWallets?.find(
		(item) => item?.walId == data?.accountref,
	);

	const onFinish = async () => {
		data.accountref = srcName.walCode;
		const res = await dispatch(initiateMpesaSTK(data));
		if (res?.payload?.success) {
			setisSuccess(true);
			setresponse(res?.payload?.messages?.message);
			await dispatch(fetchCollectionTransfer(usrObj));
		} else {
			setisSuccess(false);
			setresponse(res?.payload?.messages?.message);
		}
		await setCurrent(2);
	};

	async function handleCancel() {
		await form.resetFields();
		closeModalOne();
	}

	const steps = [
		{
			title: 'Payment Info',
			key: 'Payment Info',
			content: (
				<>
					<div className=' mb-4'>
						<label className='mb-1 flex items-center' for='accountref'>
							<span className='text-[#ff4d4f] text-[21px] mr-1 mt-[5px]'>
								*
							</span>{' '}
							Select wallet
						</label>

						<Select
							onDropdownVisibleChange={handleFetchWallets}
							loading={walletLoading}
							name={'accountref'}
							defaultValue='Choose wallet receiving payment'
							style={{
								width: '100%',
							}}
							onChange={(e) => handleSelectChange(e)}
						>
							{colWal?.map((item) => {
								return (
									<>
										<Option
											value={item?.walId}
										>{`${item?.walDesc} - ${item?.walType} (KES ${item?.walAmount})`}</Option>
									</>
								);
							})}
						</Select>
					</div>

					<Form.Item
						rules={[
							{
								required: true,
								message: 'Enter amount',
							},
						]}
						name={'phone'}
						label='M-PESA Phone No.'
					>
						<InputNumber
							maxLength={10}
							minLength={9}
							prefix={'+254'}
							onChange={handleNumberChange}
							name={'phone'}
							className='rounded-[8px] h-[44px] w-full'
						/>
					</Form.Item>

					<Form.Item
						rules={[
							{
								required: true,
								message: 'Enter amount',
							},
						]}
						name={'amount'}
						label='Amount (KES)'
					>
						<Input
							onChange={handleChange}
							name={'amount'}
							type='number'
							className='rounded-[8px] h-[44px]'
						/>
					</Form.Item>
				</>
			),
		},
		{
			title: 'Last',
			key: 'Last',
			content: (
				<div>
					{colLoading ? (
						<>
							<div className='flex flex-col'>
								<p>Initiating payment. Please wait...</p>
								<Spin className='text-2xl' />
							</div>
						</>
					) : (
						<>
							<p className='text-[15px] text-[#000] font-[600] mb-8'>
								Would you like to initiate the following payment?
							</p>

							<p className='text-[#1D191B] text-[14px] font-[600] mb-3'>
								Receiving wallet:{' '}
								<span className='font-[400]'>
									{' '}
									{srcName?.walDesc} - {srcName?.walCode}
								</span>
							</p>
							<p className='text-[#1D191B] text-[14px] font-[600] mb-3'>
								Mpesa Phone Number:{' '}
								<span className='font-[400]'> {data?.phone}</span>
							</p>

							<p className='text-[#1D191B] text-[14px] font-[600] mb-3'>
								Amount: <span className='font-[400]'> KES {data?.amount}</span>
							</p>
						</>
					)}
				</div>
			),
		},
		{
			title: 'Processing',
			key: 'Processing',
			content: (
				<div className='max-h-fit h-auto'>
					{isSuccess ? (
						<>
							<div className='flex flex-col mt-[10%] border-2 border-[#3BBB6E] p-3'>
								<div className='flex items-center'>
									<TaskAltIcon className='text-[#3BBB6E]' />
									<p className='ml-2 text-[#0A0A0A] text-[16px] font-[600]'>
										Request successful
									</p>
								</div>
								<p className='text-[13px] text-[#404040] font[600] ml-[32px]'>
									{response}
								</p>
							</div>
						</>
					) : (
						<>
							<div className='flex flex-col mt-[10%] border-2 border-[red] p-3'>
								<div className='flex items-center'>
									<HighlightOffIcon className='text-[red]' />
									<p className='ml-2 text-[#0A0A0A] text-[16px] font-[600]'>
										Request Failed
									</p>
								</div>
								<p className='text-[13px] text-[#404040] font[600] ml-[32px]'>
									{response}
								</p>
							</div>
						</>
					)}

					<div className='flex justify-center mt-10'>
						{isSuccess ? (
							<Button
								style={{
									background: '#10101E',
									color: '#fff',
									fontWeight: 500,
									fontSize: '14px',
									marginLeft: '10px',
								}}
								onClick={() => handleDone()}
							>
								Done
							</Button>
						) : (
							<>
								<div className='flex items-center'>
									<Button
										style={{
											background: '#D3D3D9',
											color: '#212143',
											fontWeight: 500,
											fontSize: '14px',
											marginLeft: '10px',
										}}
										onClick={() => handleDone()}
									>
										Close
									</Button>

									<Button
										style={{
											background: '#10101E',
											color: '#fff',
											fontWeight: 500,
											fontSize: '14px',
											marginLeft: '10px',
										}}
										onClick={() => handleReset()}
									>
										Retry
									</Button>
								</div>
							</>
						)}
					</div>
				</div>
			),
		},
	];

	const items = steps.map((item) => ({
		key: item.title,
		title: item.title,
	}));

	useEffect(() => {}, [data, colWal, globalBrnId]);

	useEffect(() => {
		setcolWal(collectionWallets);
	}, [collectionWallets]);

	return (
		<>
			<Modal
				title={
					current === 0
						? 'Mpesa STK Push'
						: current === 1
						? 'Verification'
						: 'Transfer Status'
				}
				centered
				footer={null}
				open={open}
				onOk={() => next()}
				okText={current === 2 ? 'Transfer' : 'Next'}
				onCancel={() => handleCancel()}
				width={'639px'}
				cancelButtonProps={{
					style: {
						background: '#D3D3D9',
						color: '#212143',
						fontWeight: 500,
						fontSize: '14px',
					},
				}}
				okButtonProps={{
					style: {
						background: '#10101E',
						color: '#fff',
						fontWeight: 500,
						fontSize: '14px',
					},
				}}
			>
				<>
					<Form
						layout='vertical'
						ref={formRef}
						name='control-ref'
						onFinish={onFinish}
						style={{
							maxWidth: 600,
						}}
						form={form}
					>
						<Steps current={current} items={items} />
						<div style={contentStyle}>{steps[current].content}</div>

						{current > 1 ? null : (
							<div className='flex justify-end mt-[10%]'>
								{current > 0 && current !== 2 && (
									<Button
										style={{
											background: '#D3D3D9',
											color: '#212143',
											fontWeight: 500,
											fontSize: '14px',
										}}
										onClick={() => prev()}
									>
										Previous
									</Button>
								)}

								{current < steps.length - 1 && current !== 1 && (
									<Button
										style={{
											background: '#10101E',
											color: '#fff',
											fontWeight: 500,
											fontSize: '14px',
											marginLeft: '10px',
										}}
										onClick={() => next()}
									>
										{colLoading ? <Spin /> : 'Next'}
									</Button>
								)}

								{current === 1 && (
									<Button
										disabled={colLoading}
										style={{
											background: '#10101E',
											color: '#fff',
											fontWeight: 500,
											fontSize: '14px',
											marginLeft: '10px',
										}}
										htmlType='submit'
										type='primary'
									>
										{colLoading ? <Spin /> : 'Initiate'}
									</Button>
								)}
							</div>
						)}
					</Form>
				</>
			</Modal>
		</>
	);
};

export default ColFundModalTwo;
