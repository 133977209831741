import * as React from 'react';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { useState } from 'react';
import { Button } from 'react-bootstrap';
import { HomeOutlined, PaymentsOutlined } from '@mui/icons-material';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { Typography } from '@mui/joy';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { fetchRoot, fetchSubRoot } from '../../features/nav/navSlice';
import * as Muicon from '@mui/icons-material';
import { fetchOrganizations } from '../../features/organization/organizationSlice';
import { fetchCurrentUser } from '../../features/auth/authSlice';

export default function Sidebar3() {
	const { pathname } = useLocation();
	const dispatch = useDispatch();
	const { user } = useSelector((state) => state.auth);
	const { root, subRoot } = useSelector((state) => state.nav);
	const { drawerActive } = useSelector((state) => state.drawer);

	const [open, setOpen] = useState(null);
	const [mobileSidebar, setMobileSidebar] = useState(false);
	const [rootMenu, setrootMenu] = useState(root);
	const [subRootMenu, setsubRootMenu] = useState(subRoot);

	const navigate = useNavigate();

	const location = window.location.href;

	const locationArray = location.split('/');

	const usrObj = {
		usrId: user?.usrId,
		orgId: user?.orgId,
		brnId: user?.brnId,
	};

	async function logUserOut() {
		await localStorage.clear();
		window.location.href = '/logout';
	}

	const handleClick = async (item) => {
		const data = {
			usrId: user?.usrId,
			node: item?.meId,
		};

		const res = await dispatch(fetchSubRoot(data));

		if (res?.payload?.length) {
			setsubRootMenu(res?.payload);
		}

		await navigate(item?.meLink);
		await setOpen(item?.meId);
	};

	const handleClose = (meId) => {
		setOpen(false);
	};

	const handleMobileSidebar = () => {
		setMobileSidebar(!mobileSidebar);
	};

	async function handleFetchRootFolders() {
		const data = {
			usrId: user?.usrId,
		};
		const res = await dispatch(fetchRoot(data));
		if (res?.payload?.length) {
			setrootMenu(res?.payload);
		}
	}

	async function handleFetchUserInfo() {
		await dispatch(fetchOrganizations(usrObj));
		const res = await dispatch(fetchCurrentUser());
		if (!res?.payload?.success) {
			await logUserOut();
		}
	}

	const isExpandable = rootMenu
		?.filter((item) => item?.text !== 'Beneficiaries')
		?.filter((item) => item?.text !== 'Salary')
		?.filter((item) => item.leaf === true);
	const isNotExpandable = rootMenu
		?.filter((item) => item?.text !== 'Beneficiaries')
		?.filter((item) => item?.text !== 'Salary')
		?.filter((item) => item.leaf === false);
	const home = rootMenu?.find((item) => item.text === 'Dashboard');

	useEffect(() => {
		handleFetchRootFolders();
	}, []);

	useEffect(() => {}, [rootMenu, subRootMenu]);

	useEffect(() => {
		setrootMenu(root);
	}, [root]);

	useEffect(() => {
		setsubRootMenu(subRoot);
	}, [subRoot]);

	useEffect(() => {
		handleFetchUserInfo();
	}, [pathname]);

	return (
		<>
			<aside
				style={{
					zIndex: drawerActive ? 0 : 1000,
				}}
				className={`sidebarWrapper ${mobileSidebar ? 'for-mobile' : ''}`}>
				<Button
					variant='danger'
					className={`mobileSidebarBtn ${mobileSidebar ? 'active' : ''}`}
					onClick={handleMobileSidebar}>
					{/* <img src={ArrowIcon} /> */}
				</Button>
				<List
					as='ul'
					className='sidebar'
					sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
					component='nav'
					aria-labelledby='nested-list-subheader'>
					{
						<NavLink
							to={home?.meLink}
							className={({ isActive }) => (isActive ? 'active' : '')}>
							<ListItemButton onClick={handleClose} className='sidebar__link'>
								<ListItemIcon className='!min-w-[35px]'>
									<HomeOutlined className='text-[#FFAB00] !text-[28px]' />
								</ListItemIcon>
								<ListItemText primary={home?.text} />
								<Typography variant='span' className='indicator' />
							</ListItemButton>
						</NavLink>
					}

					<NavLink
						to="/paybill"
						className={({ isActive }) => (isActive ? 'active' : '')}>
						<ListItemButton onClick={handleClose} className='sidebar__link'>
							<ListItemIcon className='!min-w-[35px]'>
								<PaymentsOutlined className='text-[#FFAB00] !text-[28px]' />
							</ListItemIcon>
							<ListItemText primary="Whitelisting Billers" />
							<Typography variant='span' className='indicator' />
						</ListItemButton>
					</NavLink>

					{/* <NavLink
						to="/settlement-accounts"
						className={({ isActive }) => (isActive ? 'active' : '')}>
						<ListItemButton onClick={handleClose} className='sidebar__link'>
							<ListItemIcon className='!min-w-[35px]'>
								<PaymentsOutlined className='text-[#FFAB00] !text-[28px]' />
							</ListItemIcon>
							<ListItemText primary="Settlement Accounts" />
							<Typography variant='span' className='indicator' />
						</ListItemButton>
					</NavLink> */}

					{isExpandable?.map((item) => {
						let Icon = '';
						if (item?.cls) {
							Icon = Muicon[`${item.cls}`];
						}
						return (
							<>
								<ListItemButton
									className={
										locationArray?.includes(item?.text?.toLowerCase())
											? 'active'
											: 'sidebar__link'
									}
									onClick={() => handleClick(item)}>
									<ListItemIcon className='!min-w-[35px]'>
										<Icon className='text-[#FFAB00] !text-[28px]' />
									</ListItemIcon>
									<ListItemText primary={item?.text} />
									{open === item?.meId ? <ExpandLess /> : <ExpandMore />}
								</ListItemButton>
								<Collapse in={open === item?.meId} timeout='auto' unmountOnExit>
									<List component='div' disablePadding>
										{subRootMenu?.map((item) => {
											return (
												<>
													<NavLink
														to={item?.meLink}
														className={({ isActive }) =>
															isActive ? 'active' : ''
														}>
														<ListItemButton sx={{ pl: 4, ml: 2 }}>
															<ListItemIcon className='!min-w-[35px]'>
																<FiberManualRecordIcon className='!text-[12px] text-[#222]' />
															</ListItemIcon>
															<ListItemText
																className='text-[#222]'
																primary={item?.text}
															/>
														</ListItemButton>
													</NavLink>
												</>
											);
										})}
									</List>
								</Collapse>
							</>
						);
					})}

					{isNotExpandable
						?.filter((item) => item.text !== 'Dashboard')
						.map((item) => {
							let Icon = '';
							if (item?.cls) {
								Icon = Muicon[`${item.cls}`];
							}
							return (
								<>
									<NavLink
										to={item?.meLink}
										className={({ isActive }) => (isActive ? 'active' : '')}>
										<ListItemButton
											onClick={handleClose}
											className='sidebar__link'>
											<ListItemIcon className='!min-w-[35px]'>
												<Icon className='text-[#FFAB00] !text-[28px]' />
											</ListItemIcon>
											<ListItemText primary={item?.text} />
											<Typography variant='span' className='indicator' />
										</ListItemButton>
									</NavLink>
								</>
							);
						})}
				</List>
			</aside>
		</>
	);
}
